import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login'),
    },

    {
        path: '/PdfRecommend',
        name: 'PdfRecommend',
        component: () => import('../views/filling/volunteer/PdfRecommend'),
    },
    {
        path: '/PdfVolunteer',
        name: 'PdfVolunteer',
        component: () => import('../views/filling/volunteer/PdfVolunteer'),
    },
    {
        path: '/',
        name: '',
        component: () => import('../views/index'),
        children: [
            {
                path: '/',
                name: 'info',
                component: () => import('../views/expert/Info'),
            },
           
            {
                path: '/user_info',
                component: () => import('../views/user/userInfo/'),
                children: [
                    {
                        path: '/',
                        name: 'user_info',
                        component: () => import('../views/user/userInfo/UserInfo'),
                    },
                ]
            },

            {
                path: '/pdf_plan',
                component: () => import('../views/pdf_plan'),
                children: [
                    {
                        path: 'plan',
                        name: 'plan',
                        component: () => import('../views/pdf_plan/plan'),
                    },
                    {
                        path: 'zhengce',
                        name: 'zhengce',
                        component: () => import('../views/pdf_plan/zhengce'),
                    },
                ]
            },

            {
                path: '/expert/expert',
                name: 'expert',
                component: () => import('../views/expert/Expert'),
            },
            {
                path: '/editExpert',
                name: 'editExpert',
                component: () => import('../views/expert/EditExpert'),
            },
            {
                path: '/report',
                name: 'report',
                component: () => import('../views/user/report'),
            },
            {
                path: '/expert_list',
                name: 'expert_list',
                component: () => import('../views/expert/ExpertList'),
            },
            {
                path: '/SearchSchool',
                name: 'SearchSchool',
                component: () => import('../views/zhuanjiamoshi/SearchSchool'),
            },


            {
                path: '/filling',
                name: 'filling',
                component: () => import('../views/filling/index'),

            },

            {
                path: 'volunteer',
                name: 'volunteer',
                component: () => import("../views/filling/volunteer/index"),
            },
            {
                path: '/SearchSpecialty',
                name: 'SearchSpecialty',
                component: () => import('../views/specialty/SearchSpecialty'),
            },
            {
                path: '/user',
                name: 'user',
                component: () => import('../views/user/index'),
            }
        ]
    },
    {
        path: '/solution',
        name: 'solution',
        component: () => import('../views/zhuanjiamoshi/solution'),
    },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes
});


router.beforeEach((to, from, next) => {
    // console.log(to.name)
    // to: Route: 即将要进入的目标 路由对象
    // from: Route: 当前导航正要离开的路由
    // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
    const ExpertInfo = localStorage.getItem('ExpertInfo') // 是否登录
    // 未登录状态；当路由到nextRoute指定页时，跳转至login

    if (!ExpertInfo && to.name !== 'login') {
        router.push('/Login')
    } else {
        next()
    }
    // // 已登录状态；当路由到login时，跳转至home
    if (to.name === 'login') {
        if (ExpertInfo) {
            router.push('/')
        } else {
            next()
        }
    }
    next();
})

export default router
